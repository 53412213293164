import React from "react"
import { Helmet } from "react-helmet"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons"
import BlueDots from "../../static/images/support-blue-dots.svg"
import BlueDot from "../../static/images/support-blue-dot.svg"
import BlueCicle from "../../static/images/support-blue-circle.svg"
import BlueDots2 from "../../static/images/support-blue-dots-2.svg"

export default function Faqs({ data, pageContext }) {
  const { faqs } = data.support

  const questions = faqs.filter(
    faq => faq.frontmatter.category === pageContext.category
  )

  const categoryTitle = questions[0]?.frontmatter?.categoryTitle

  return (
    <Layout>
      <Helmet>
        <title>{categoryTitle} | Support | IBDmate</title>
      </Helmet>
      <div className="layout-support">
        <div className="blue-bg">
          <form>
            <h1>How can we help?</h1>
          </form>
          <div className="vectors-left">
            <img src={BlueDots} id="blue-dots" alt="" />
            <img src={BlueDot} id="blue-dot" alt="" />
          </div>
          <div className="vectors-right">
            <img src={BlueCicle} id="blue-circle" alt="" />
            <img src={BlueDots2} id="blue-dots-2" alt="" />
          </div>
        </div>
        <div className="wrapper content">
          <nav>
            <Link to="/support/">IBDmate support</Link>{" "}
            <FontAwesomeIcon icon={faChevronRight} />{" "}
            {categoryTitle}
          </nav>
          <h2>{categoryTitle}</h2>
          {questions.map(faq => (
            <article key={faq.id}>
              <Link to={faq.fields.slug}>{faq.frontmatter.question}</Link>
            </article>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query Faqs {
    support: allMarkdownRemark {
      faqs: nodes {
        fields {
          slug
        }
        frontmatter {
          question
          category
          categoryTitle
        }
        excerpt
        id
      }
    }
  }
`
